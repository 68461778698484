import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_IS_OPEN_LIST_DICT } from '../../reactivities/operations/queries/dictionary';
import { GET_LANGUAGE } from '../../reactivities/operations/queries/language';
import {
  CREATE_DICTIONARY,
  DELETE_DICTIONARY,
  updateDictionaryMutations,
  UPDATE_DICTIONARY,
} from '../../reactivities/operations/mutations/dictionary';
import { Row, Table, Col, Button, Modal, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faLanguage, faPen, faSpinner, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { GET_DICTIONARY } from '../../queries';
import moment from 'moment';

const Dictionary = () => {
  const dictRef = useRef<HTMLDivElement>(null);
  const queryIsOpenListDict = useQuery(GET_IS_OPEN_LIST_DICT);
  const { data: queryGetLanguage } = useQuery(GET_LANGUAGE);
  const languages = queryGetLanguage ? queryGetLanguage.getLanguage.languages : [];
  const { data: dictionaryData, refetch: refetchDictData } = useQuery<
    { getDictionary: DictionaryResponse[] },
    { languageId: null }
  >(GET_DICTIONARY, {
    variables: { languageId: null },
  });

  const dictionaries = dictionaryData ? dictionaryData?.getDictionary : [];
  const [createDictionary, { loading: loadingCreateDictionary, error: errorCreateDictionary }] = useMutation<{
    languageId: string;
    name: string;
    tags: [string];
  }>(CREATE_DICTIONARY, {
    onCompleted: () => {
      setIsOpenModalUpdateDict(false);
    },
  });
  const [updateDictionary, { loading: loadingUpdateDictionary, error: errorUpdateDictionary }] = useMutation<{
    languageId: string;
    name: string;
    tags: [string];
  }>(UPDATE_DICTIONARY, {
    onCompleted: () => {
      setIsOpenModalUpdateDict(false);
      refetchDictData();
    },
  });
  const [deleteDictionary, { loading: loadingDeleteDictionary, error: errorDeleteDictionary }] = useMutation<any>(
    DELETE_DICTIONARY,
    {
      onCompleted: () => {
        setIsOpenModalDeleteDict(false);
      },
    }
  );
  const loadingSaveDict = loadingUpdateDictionary || loadingCreateDictionary;
  const [isOpenModalUpdateDict, setIsOpenModalUpdateDict] = useState(false);
  const [isOpenModalDeleteDict, setIsOpenModalDeleteDict] = useState(false);

  const [dictIdDelete, setDictIdDelete] = useState(null);

  // state dict also check for create or update
  const [dictUpdate, setDictUpdate] = useState<any>(null);
  // state dictName for dict name
  const [dictName, setDictName] = useState('');
  // state for lang id
  const [langId, setLangId] = useState('');
  // state for tag
  const [inputTag, setInputTag] = useState('');
  const [tags, setTags] = useState<any>([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const onKeyDown = (e: any) => {
    const { key } = e;
    const trimmedInput = inputTag.trim();

    if (key === ',' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState: any) => [...prevState, trimmedInput]);
      setInputTag('');
    }
    if (key === 'Backspace' && !inputTag.length && tags.length) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      setTags(tagsCopy);
      setInputTag(poppedTag);
    }
    setIsKeyReleased(false);
  };
  const onKeyUp = () => {
    setIsKeyReleased(true);
  };
  const deleteTag = (index: any) => {
    setTags((prevState: any) => prevState.filter((tag: any, i: any) => i !== index));
  };
  // handle click outside sidebar
  useEffect(() => {
    const handleClickOutsideDictionary: EventListener = (event: Event): void => {
      if (document.getElementById('modal-update-dict') || document.getElementById('modal-delete-dict')) return;
      if (document.getElementsByClassName('ctn-dict-btn')[0].contains(event.target as HTMLElement)) {
        return;
      }
      if (event.target && dictRef.current && !dictRef.current.contains(event.target as HTMLElement)) {
        updateDictionaryMutations.updateIsOpenListDict(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideDictionary);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDictionary);
    };
  }, [dictRef]);
  const handleSaveDict = async () => {
    const dataSave: any = {
      variables: {
        languageId: langId,
        name: dictName,
        tags: tags,
      },
    };
    if (dictUpdate) {
      dataSave.variables.dictionaryId = dictUpdate.id;
      await updateDictionary(dataSave);
    } else {
      await createDictionary(dataSave);
    }
    await refetchDictData();
  };

  const handleDeleteDict = async () => {
    await deleteDictionary({
      variables: {
        dictionaryId: dictIdDelete,
      },
    });
    await refetchDictData();
  };
  useEffect(() => {
    if (dictUpdate) {
      setDictName(dictUpdate?.name);
      setTags(dictUpdate?.tags);
      setLangId(dictUpdate.language.id);
    } else {
      setDictName('');
      setTags([]);
      setLangId('83bRQwLKpwe7');
    }
  }, [dictUpdate]);

  return (
    <div
      id="container-dictionary"
      className="container-dictionary"
      style={{ display: `${queryIsOpenListDict.data.isOpenListDict ? 'block' : 'none'}` }}
      ref={dictRef}
    >
      <Row style={{ justifyContent: 'center' }} noGutters>
        <Col md="8" xs="11">
          <div className="mt-3 text-right">
            <div
              id="button-create-dict"
              style={{ color: 'white' }}
              className="btn btn-simon"
              onClick={() => {
                setIsOpenModalUpdateDict(true);
                setDictUpdate(null);
              }}
            >
              Create
            </div>
          </div>
          <div className={`mt-3 p-4 dashboard-box ${dictionaries.length > 0 ? '':'no-dictionary'}`}>
            {dictionaries.length > 0 ? (
              <div className="table-dictionary">
                {dictionaries.map((item: any) => (
                  <div className="row-item" key={item.id}>
                    <div className="name-dictionary">
                      <FontAwesomeIcon icon={faBook} width={25} height={18} /> {item.name}
                    </div>
                    <div className="tag-dictionary">{item.tags.length} words</div>
                    <div className="language-dictionary">
                      <FontAwesomeIcon className="icon-lang" icon={faLanguage} width={24} height={16} color="#C8247F" />{' '}
                      <span className="lang"> {item.language.label} </span>
                    </div>
                    <div className="created-dictionary">
                      {moment(parseInt(item.creation_date)).format('MMMM DD, YYYY')}
                    </div>
                    <div className="action-dictionary">
                      <Button
                        className="btn-dict-action"
                        color="link"
                        onClick={() => {
                          setIsOpenModalUpdateDict(true);
                          setDictUpdate(item);
                        }}
                      >
                        <FontAwesomeIcon className="icon-lang" icon={faPen} width={24} height={16} color="#fff" />
                      </Button>
                      <Button
                        className="btn-dict-action"
                        color="link"
                        onClick={() => {
                          setIsOpenModalDeleteDict(true);
                          setDictIdDelete(item.id);
                        }}
                      >
                        <FontAwesomeIcon className="icon-lang" icon={faTrashAlt} width={24} height={16} color="#fff" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-dictionary-text">No dictionaries have been created yet. Create your first dictionary.</div>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        id="modal-update-dict"
        isOpen={isOpenModalUpdateDict}
        fade={false}
        toggle={() => setIsOpenModalUpdateDict(!isOpenModalUpdateDict)}
      >
        <div className="txt-title-dict">Custom Dictionary</div>
        <span className="con-icon-close" onClick={() => setIsOpenModalUpdateDict(!isOpenModalUpdateDict)}>
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>
        <div className="label-inp">Dictionary name</div>
        <div className="ctn-inp-select-dict">
          <Input
            className="inp-select-dict dict-name"
            type="text"
            name="dictName"
            value={dictName}
            onChange={e => setDictName(e.target.value)}
            placeholder="Type your dictionary name..."
          />
        </div>
        <div className="label-inp">Language</div>
        <div className="ctn-inp-select-dict">
          <Input
            className="inp-select-dict"
            type="select"
            name="select"
            id="inp-select-dict"
            value={langId}
            onChange={e => setLangId(e.target.value)}
          >
            {languages.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </Input>
        </div>
        <div className="label-inp">Tags</div>
        <div className="box-inp-tag">
          {tags.map((tag: string, idx: number) => (
            <div className="tag" key={idx}>
              {tag}
              <button onClick={() => deleteTag(idx)}>x</button>
            </div>
          ))}
          <input
            style={{ width: "100%" }}
            placeholder="Adds names, abbreviations and Acronyms..."
            value={inputTag}
            onKeyDown={onKeyDown}
            onChange={e => setInputTag(e.target.value)}
          />
        </div>
        <div className="label-inp comma-txt">Enter comma-separated values</div>
        <div className="ctn-btn-save-dict">
          <Button onClick={handleSaveDict} className="btn-save-dict link">
            {loadingSaveDict ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
            ) : (
              <div>
                <span>Save</span>
              </div>
            )}
          </Button>
        </div>
      </Modal>
      <Modal
        id="modal-delete-dict"
        isOpen={isOpenModalDeleteDict}
        fade={false}
        toggle={() => setIsOpenModalDeleteDict(!isOpenModalDeleteDict)}
      >
        <div className="txt-title-dict">Do you want to delete this dictionary ?</div>
        <span className="con-icon-close" onClick={() => setIsOpenModalDeleteDict(!isOpenModalDeleteDict)}>
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>
        <div className="ctn-btn-save-dict">
          <Button onClick={handleDeleteDict} className="btn-save-dict link">
            {loadingDeleteDictionary ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
            ) : (
              <div>
                <span>Confirm</span>
              </div>
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Dictionary;
