import React from 'react';

const IconCC = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 13.5H2.25C1.65351 13.4992 1.08167 13.2619 0.659886 12.8401C0.238102 12.4183 0.000793738 11.8465 0 11.25L0 2.25C0.000793738 1.65351 0.238102 1.08167 0.659886 0.659886C1.08167 0.238102 1.65351 0.000793738 2.25 0H15.75C16.3465 0.000793738 16.9183 0.238102 17.3401 0.659886C17.7619 1.08167 17.9992 1.65351 18 2.25V11.25C17.9992 11.8465 17.7619 12.4183 17.3401 12.8401C16.9183 13.2619 16.3465 13.4992 15.75 13.5ZM1.5 4.5V11.25C1.50026 11.4488 1.57937 11.6394 1.71996 11.78C1.86056 11.9206 2.05117 11.9997 2.25 12H15.75C15.9488 11.9997 16.1394 11.9206 16.28 11.78C16.4206 11.6394 16.4997 11.4488 16.5 11.25V4.5H1.5ZM2.25 1.5C2.05117 1.50026 1.86056 1.57937 1.71996 1.71996C1.57937 1.86056 1.50026 2.05117 1.5 2.25V3H16.5V2.25C16.4997 2.05117 16.4206 1.86056 16.28 1.71996C16.1394 1.57937 15.9488 1.50026 15.75 1.5H2.25ZM8.25 10.5H3.75C3.55109 10.5 3.36032 10.421 3.21967 10.2803C3.07902 10.1397 3 9.94891 3 9.75C3 9.55109 3.07902 9.36032 3.21967 9.21967C3.36032 9.07902 3.55109 9 3.75 9H8.25C8.44891 9 8.63968 9.07902 8.78033 9.21967C8.92098 9.36032 9 9.55109 9 9.75C9 9.94891 8.92098 10.1397 8.78033 10.2803C8.63968 10.421 8.44891 10.5 8.25 10.5Z" fill="white" />
    </svg>

  );
};

export default IconCC;
