import React from 'react';

const IconCalendar = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.829 18.0005C2.07895 17.9997 1.35984 17.7014 0.829472 17.171C0.299104 16.6407 0.000794128 15.9216 0 15.1715L0 4.8865C0.00096082 4.18116 0.265001 3.50155 0.740482 2.98058C1.21596 2.4596 1.86869 2.13473 2.571 2.0695V0.7715C2.571 0.566886 2.65228 0.370651 2.79697 0.225967C2.94165 0.0812828 3.13789 0 3.3425 0C3.54711 0 3.74335 0.0812828 3.88803 0.225967C4.03272 0.370651 4.114 0.566886 4.114 0.7715V2.0575H8.229V0.7715C8.229 0.566886 8.31028 0.370651 8.45497 0.225967C8.59965 0.0812828 8.79589 0 9.0005 0C9.20511 0 9.40135 0.0812828 9.54603 0.225967C9.69072 0.370651 9.772 0.566886 9.772 0.7715V2.0575H13.887V0.7715C13.887 0.566886 13.9683 0.370651 14.113 0.225967C14.2577 0.0812828 14.4539 0 14.6585 0C14.8631 0 15.0593 0.0812828 15.204 0.225967C15.3487 0.370651 15.43 0.566886 15.43 0.7715V2.0715C16.1318 2.13693 16.784 2.46162 17.2592 2.98215C17.7344 3.50268 17.9986 4.18167 18 4.8865V15.1715C17.9992 15.9216 17.7009 16.6407 17.1705 17.171C16.6402 17.7014 15.9211 17.9997 15.171 18.0005H2.829ZM1.543 15.1715C1.54353 15.5124 1.67919 15.8392 1.92024 16.0803C2.1613 16.3213 2.48809 16.457 2.829 16.4575H15.171C15.5119 16.457 15.8387 16.3213 16.0798 16.0803C16.3208 15.8392 16.4565 15.5124 16.457 15.1715V8.2295H1.543V15.1715ZM16.457 6.6865V4.8865C16.4565 4.54559 16.3208 4.2188 16.0798 3.97774C15.8387 3.73669 15.5119 3.60103 15.171 3.6005H2.829C2.48809 3.60103 2.1613 3.73669 1.92024 3.97774C1.67919 4.2188 1.54353 4.54559 1.543 4.8865V6.6865H16.457Z" fill="white" />
    </svg>
  );
};

export default IconCalendar;
