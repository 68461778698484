import React, { useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { EditorState } from 'draft-js';
import IconSwitch from '../svg/IconSwitch';
import IconSwitchOff from '../svg/IconSwitchOff';
import IconClose from '../svg/IconClose';

type BucketProps = {
  current?: ImportedMedia | null;
  children: React.ReactNode;
  currentTimePlaying?: number | null;
  editorState: EditorState;
  canEdit?: boolean;
  isShowButtonClose: boolean;
  showFull?: boolean;
  onCloseBucket: () => void;
  onBackHistory: () => void;
};

enum TranscribeStatus {
  TRANSCRIBED = "transcribed",
  TRANSCRIBING = "transcribing",
  REJECTED = "rejected",
  FAILED = "failed",
  TRANSLATED = "translated",
  TRANSLATING = "translating",
  READY = "ready",
  TRANSCRIBE_PAID = "transcribe_paid",
  TRANSLATE_PAID = "translate_paid",
  UPLOADING = "uploading",
  TRANSCODING = "transcoding"
}

const Bucket = ({
  current,
  children,
  editorState,
  currentTimePlaying,
  isShowButtonClose,
  canEdit,
  showFull,
  onCloseBucket,
  onBackHistory,
}: BucketProps) => {
  const mediaRef = useRef<any>(null);
  const router = useRouter();

  useEffect(() => {
    if (currentTimePlaying !== null && mediaRef.current !== null) {
      mediaRef.current.currentTime = currentTimePlaying;
    }
  }, [currentTimePlaying, mediaRef]);

  useEffect(() => {
    if (mediaRef.current == null) return;

    (mediaRef.current as HTMLVideoElement).oncanplay = function(event: Event): void {
      const videoEl = event.target as HTMLVideoElement;
      const vh: number = videoEl.videoHeight || videoEl.height;
      const vw: number = videoEl.videoWidth || videoEl.width;

      if (vh < vw) return;

      videoEl.setAttribute('height', '266');
    };
  }, [current, mediaRef]);

  useEffect(() => {
    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 32 /* `space` key */) {
        if (canEdit) return
        // e.preventDefault();
        if (editorState.getSelection().getHasFocus()) {
          if (mediaRef.current.paused) mediaRef.current.play();
          else mediaRef.current.pause();
        }
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [editorState, mediaRef]);

  const renderVideoOrAudio = useMemo(() => {
    if (!current) return null;

    if (current.type === 'audio') {
      return (
        <audio ref={mediaRef} controls key={current.id}>
          <source src={current.url} type="audio/mp3" />
        </audio>
      );
    }

    return (
      <React.Fragment>
        <video ref={mediaRef} controls key={current.id}>
          <source src={current.url} type="video/mp4" />
        </video>
      </React.Fragment>

    );
  }, [current, showFull]);

  return (
    <Container className={`bucket-container ${showFull && "show-full"}`}>
      {showFull && <div className={`media-container ${showFull && "media-container-full"}`}>{renderVideoOrAudio}</div>}
      <Row className={`${showFull && "row-full"}`}>
        <Col className="bucket-col">
          <div className="con-file-name">
            <span onClick={() => onBackHistory()} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCaretLeft} width={24} height={24} />
            </span>
            <span className="file-name">{current ? current.name : 'Select a media file'}</span>
            {isShowButtonClose && (
              <IconClose onClick={onCloseBucket} />
            )}
          </div>
          {!showFull && <div className="media-container">{renderVideoOrAudio}</div>}
          {current?.status === TranscribeStatus.TRANSCRIBING &&
            <div style={{ textAlign: 'center', color: "#999" }}>Transcribing...</div>}
          {children}
        </Col>
        {/* <Col id="bucket-col-2" xs="1" m="2" md="3" className="bucket-col-2"></Col> */}
      </Row>
    </Container>
  );
};
export default Bucket;
