import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Editor from '../components/Layouts/Editor';
import { GetServerSidePropsContext } from 'next';
import { ApolloClient, gql, InMemoryCache, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch'
const { AbortController } = require('abortcontroller-polyfill/dist/cjs-ponyfill'); //In Next SSR Apollo uses the AbortController polyfill which is causing problems. We will load it ourselves and setup manually.


type ProjectPageProps = {
  name: string;
  thumb: string;
  error: string;
};
const Project = ({ name, thumb }: ProjectPageProps) => {
  const router = useRouter();
  let { projectid } = router.query;

  if (typeof projectid === 'object' && projectid.length > 0) {
    [projectid] = projectid;
  }
  name = `${name} | Simon Says`;
  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={name} />
        <link rel="apple-touch-icon" href="/simonsays-logo.png" />
        <link rel="manifest" href="/manifest.json" />
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={name} />
        <meta property="og:description" content="Check out this video I cut with Simon Says." />
        <meta property="og:site_name" content="Simon Says Assemble" />
        <meta property="og:image" content={thumb} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={name} />
        {/* <meta name="twitter:card" content="player" /> */}
        {/* <meta name="twitter:player" content="https://media.giphy.com/media/uWlpPGquhGZNFzY90z/giphy.gif" />
        <meta name="twitter:player:width" content="480" />
        <meta name="twitter:player:height" content="480" /> */}
        <meta name="twitter:description" content="Check out this video I cut with Simon Says." />
        <meta name="twitter:image" content={thumb} />
        <meta name="viewport" content="initial-scale=0" />

        <title>Simon Says Assemble</title>
      </Head>
      {projectid && typeof projectid !== 'object' && <Editor projectid={projectid} />}
    </Fragment>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let props = { name: 'Simon Says Assemble Project', thumb: process.env.NEXT_PUBLIC_DEFAULT_THUMB };
  if (!context.params?.projectid) return { props };

  let projectid = context.params.projectid;
  if (typeof projectid === 'object' && projectid.length > 0) {
    [projectid] = projectid;
  }

  const GET_ASSEMBLY_PROJECT_PUBLIC = gql`
    query getAssemblyProjectPublicData($projectId: ID!) {
      getAssemblyProjectPublicData(projectId: $projectId) {
        name
        thumb
      }
    }
  `;

  if (!projectid || typeof projectid === 'object') return; //shouldn't happen

  // By providing signal ourselves, Apollo isn't actually going to use it, as it won't have a context, but will skip creating a context + signal. So it will not use the polyfill
  const controller = new AbortController();
  const signal = controller.signal;

  const apolloClient = new ApolloClient({
    ssrMode: true,
    link: new HttpLink({
      uri: process.env.NEXT_PUBLIC_APOLLO_URI,
      fetchOptions: {
        signal, //By providing signal ourselves, Apollo isn't actually going to use it, as it won't have a context, but will skip creating a context + signal. So it will not use the polyfill
      },
      fetch,
    }),
    cache: new InMemoryCache(),
  });
  try {
    const assemblyProjectData = await apolloClient.query({
      query: GET_ASSEMBLY_PROJECT_PUBLIC,
      variables: { projectId: projectid },
    });
    const retrievedProps = assemblyProjectData.data.getAssemblyProjectPublicData; 
    if (retrievedProps.name) {
      props.name = retrievedProps.name
    }
    if (retrievedProps.thumb) {
      props.thumb = retrievedProps.thumb
    }
  } catch (err) {
    console.error(err);
  }
  return { props };
}

export default Project;
