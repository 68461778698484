import { gql } from '@apollo/client';
export const GET_LANGUAGE = gql`
  query getLanguage {
    getLanguage {
      languages {
        id
        label
      }
    }
  }
`;
