import React from 'react';

type IconCloseProps = {
  onClick: () => void;
};

const IconClose = ({ onClick }: IconCloseProps) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={onClick}>
      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="14.5" r="14.5" fill="#272E49" />
        <path d="M16.1422 14.7279L21.092 19.6777C21.2795 19.8652 21.3849 20.1196 21.3849 20.3848C21.3849 20.65 21.2795 20.9043 21.092 21.0919C20.9045 21.2794 20.6501 21.3848 20.3849 21.3848C20.1197 21.3848 19.8653 21.2794 19.6778 21.0919L14.728 16.1421L9.77828 21.0919C9.59074 21.2794 9.33639 21.3848 9.07117 21.3848C8.80596 21.3848 8.5516 21.2794 8.36407 21.0919C8.17653 20.9043 8.07117 20.65 8.07117 20.3848C8.07117 20.1196 8.17653 19.8652 8.36407 19.6777L13.3138 14.7279L8.36407 9.77817C8.17653 9.59064 8.07117 9.33628 8.07117 9.07107C8.07117 8.80585 8.17653 8.5515 8.36407 8.36396C8.5516 8.17642 8.80596 8.07107 9.07117 8.07107C9.33639 8.07107 9.59074 8.17642 9.77828 8.36396L14.728 13.3137L19.6778 8.36396C19.8653 8.17642 20.1197 8.07107 20.3849 8.07107C20.6501 8.07107 20.9045 8.17642 21.092 8.36396C21.2795 8.5515 21.3849 8.80585 21.3849 9.07107C21.3849 9.33628 21.2795 9.59064 21.092 9.77817L16.1422 14.7279Z" fill="#8F9BB3" />
      </svg>

    </div>
  );
};

export default IconClose;
