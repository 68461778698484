import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Input, Modal } from 'reactstrap';
import IconCC from "../../components/svg/IconCC"
import IconCalendar from "../../components/svg/IconCalendar"
import IconLock from "../../components/svg/IconLock"
import IconSecure from "../../components/svg/IconSecure"
import { useTextInput } from '../../common/custom-hook'
import { validate } from "../../services/ValidateCC"

type ModalAddCreditProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  addCard: (card: any) => void;
}


const ModalAddCredit = ({ isOpen, toggle, addCard }: ModalAddCreditProps) => {

  const creditCard = useTextInput(null, handleValidateCredit);
  const nameOnCard = useTextInput(null, handleValidateCredit);
  const dateOnCard = useTextInput(null, handleValidateCredit);
  const cvcOnCard = useTextInput(null, handleValidateCredit);
  const [isValid, setValid] = useState(false);

  const formCredit = useRef<any>({
    nameOnCard: 'Field name on card is required',
    creditCard: 'Field credit card is required',
    dateOnCard: 'Field MM and YY are required',
    cvcOnCard: 'Field cvc is required',
  });

  function handleValidateCredit(event: any) {
    const { name } = event.target;
    const value = event.target.value;

    let validation: boolean | string = true;

    if (name === 'nameOnCard') {
      validation = validate.nameOnCard(value);
    }

    if (name === 'creditCard') {
      validation = validate.creditCard(value.replace(/[^\d]/g, ''));
    }

    if (name === 'cvcOnCard') {
      validation = validate.serial([
        () => validate.isNumber(value, 'CVC must be a number'),
        () => validate.expectLength(value, 3, 'CVC required 3 numbers'),
      ]);
    }

    if (name === 'dateOnCard') {
      validation = validate.dateOnCard(value);
    }

    formCredit.current[name] = validation;

    const validations = Object.values(formCredit.current);

    setValid(!validations.some(item => item !== true))

    return true;
  }

  const formatCardNumber = (value: string) => {
    if (!value) return
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, '')

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter(group => !!group).join(' ')
    )
  }

  return (
    <Modal id="modal-add-credit"
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      isOpen={isOpen} fade={false}
    >

      <div className="txt-add-credit">Add Credit</div>

      <span className="con-icon-close"
        onClick={() => toggle(!isOpen)}
      >
        <FontAwesomeIcon icon={faTimes} width={16} height={16} />
      </span>

      <div className="txt-label">Card Holder Name</div>
      <div className="con-input">
        <Input name="nameOnCard" placeholder="Enter card holder name"
          value={nameOnCard.value} onChange={nameOnCard.onChange}
        />
      </div>

      <div className="txt-label">Credit Card Number</div>
      <div className="d-flex flex-row con-input">
        <div className="d-flex con-icon">
          <IconCC />
        </div>

        <Input name="creditCard" id="cc-number" placeholder="xxxx - xxxx - xxxx - xxxx" maxlength={19}
          value={formatCardNumber(creditCard.value)} onChange={creditCard.onChange}
        />
      </div>

      <div className="d-flex flex-row">
        <div style={{ marginRight: 25 }}>
          <div className="txt-label">Expiration</div>
          <div className="d-flex flex-row con-input" style={{ width: 253 }}>
            <div className="d-flex con-icon">
              <IconCalendar />
            </div>

            <Input placeholder="MM/YY" name="dateOnCard" value={dateOnCard.value} onChange={dateOnCard.onChange} />
          </div>
        </div>

        <div >
          <div className="txt-label">CVC</div>
          <div className="d-flex flex-row con-input" style={{ width: 253 }}>

            <div className="d-flex con-icon">
              <IconLock />
            </div>
            <Input placeholder="XXX" maxlength={3} name="cvcOnCard"
              value={cvcOnCard.value} onChange={cvcOnCard.onChange}
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: 18 }} className="d-flex flex-row">

        <div className="d-flex con-icon">
          <IconSecure />
        </div>

        <div className="txt-encrypt">This form is secure and encrypted.</div>
      </div>

      <div style={{ marginTop: 53 }} className="d-flex justify-content-center">
        <div className={`btn-update ${isValid && 'btn-update-active'}`}
          onClick={() => addCard({
            nameOnCard: nameOnCard.value,
            creditCard: creditCard.value.replace(/[^\d]/g, ''),
            dateOnCard: dateOnCard.value,
            cvcOnCard: cvcOnCard.value
          })}
        >
          Update
        </div>
      </div>

    </Modal>
  );
};

export default ModalAddCredit;
