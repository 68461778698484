import React from 'react';

const IconSecure = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 18H2.25C1.65351 17.9992 1.08167 17.7619 0.659886 17.3401C0.238102 16.9183 0.000793738 16.3465 0 15.75L0 9.75C0.000793738 9.15351 0.238102 8.58167 0.659886 8.15989C1.08167 7.7381 1.65351 7.50079 2.25 7.5V4.5C2.25 3.30653 2.72411 2.16193 3.56802 1.31802C4.41193 0.474106 5.55653 0 6.75 0C7.94347 0 9.08807 0.474106 9.93198 1.31802C10.7759 2.16193 11.25 3.30653 11.25 4.5V7.5C11.8465 7.50079 12.4183 7.7381 12.8401 8.15989C13.2619 8.58167 13.4992 9.15351 13.5 9.75V15.75C13.4992 16.3465 13.2619 16.9183 12.8401 17.3401C12.4183 17.7619 11.8465 17.9992 11.25 18ZM2.25 9C2.05117 9.00026 1.86056 9.07937 1.71996 9.21996C1.57937 9.36056 1.50026 9.55117 1.5 9.75V15.75C1.50026 15.9488 1.57937 16.1394 1.71996 16.28C1.86056 16.4206 2.05117 16.4997 2.25 16.5H11.25C11.4488 16.4997 11.6394 16.4206 11.78 16.28C11.9206 16.1394 11.9997 15.9488 12 15.75V9.75C11.9997 9.55117 11.9206 9.36056 11.78 9.21996C11.6394 9.07937 11.4488 9.00026 11.25 9H2.25ZM6.75 1.5C5.95435 1.5 5.19129 1.81607 4.62868 2.37868C4.06607 2.94129 3.75 3.70435 3.75 4.5V7.5H9.75V4.5C9.75 3.70435 9.43393 2.94129 8.87132 2.37868C8.30871 1.81607 7.54565 1.5 6.75 1.5ZM6.75 14.249C6.55134 14.2487 6.36088 14.1698 6.22031 14.0294C6.07975 13.889 6.00053 13.6987 6 13.5V12C6 11.8011 6.07902 11.6103 6.21967 11.4697C6.36032 11.329 6.55109 11.25 6.75 11.25C6.94891 11.25 7.13968 11.329 7.28033 11.4697C7.42098 11.6103 7.5 11.8011 7.5 12V13.5C7.49974 13.6988 7.42063 13.8894 7.28004 14.03C7.13944 14.1706 6.94883 14.2497 6.75 14.25V14.249Z" fill="#8F9BB3" />
    </svg>
  );
};

export default IconSecure;
