import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { buttonStyles, toolbarStyles } from './theme';

const myDraftStyle: { [key: string]: string } = {
  HIGHLIGHT: 'HIGHLIGHT',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  STRIKETHROUGH: 'STRIKETHROUGH',
};
const myActions = {
  INSERT: 'INSERT',
  APPEND: 'APPEND',
  REMOVE: 'REMOVE',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  STRIKETHROUGH: 'STRIKETHROUGH',
};

const EditorStyles = {
  [myDraftStyle.HIGHLIGHT]: {
    backgroundColor: 'rgba(200, 36, 127, 0.6)', // css property
  },
  [myDraftStyle.BOLD]: {
    fontWeight: 'bold',
  },
  [myDraftStyle.ITALIC]: {
    fontStyle: 'italic',
  },
  [myDraftStyle.STRIKETHROUGH]: {
    textDecoration: 'line-through',
  },
};

function getInlineToolbarComp() {
  const inlineToolbarPlugin = createInlineToolbarPlugin({
    theme: { buttonStyles, toolbarStyles },
  });

  const { InlineToolbar } = inlineToolbarPlugin;

  const plugins = [inlineToolbarPlugin, { customStyleMap: { ...EditorStyles } }];
  return {
    plugins,
    InlineToolbar,
  };
}

export { myDraftStyle, myActions, getInlineToolbarComp };
