export const validate = {
  hasError: (value: any) => typeof value === "string",

  serial: (validates: Function[] = []) => {
    if (!validates.length)
      for (const validate of validates) {
        const message = validate();
        if (typeof message === "string") return message;
      }
    return true;
  },

  required: (value: any, message: any) => !!value || (message || "This field is required"),

  isNumber: (value: any, message: any) => !!Number(value) || (message || "This field is required"),

  expectLength: (value: any, expectNum: any, message: any) => value.length === expectNum || (message || "This field is required"),

  nameOnCard: function (value: String) {
    const messageDefault = "Name is too short";
    if (!value) return messageDefault;
    return true;
    // const fullName = value.split(" ");
    // if(fullName.length !== 1) return messageDefault;

    // return fullName.every(name => name.length < 0) || "Name is too short";
  },

  projectName: (value: any) => (/^[A-Za-z0-9\s()-_]+$/g.test(value) && value.length <= 60) || "Project name is invalid",

  dateOnCard: function (value: any) {
    const messageDefault = "Expiry date is invalid";

    const [MM, YY] = value.split("/");
    if (!MM || !YY) return messageDefault;

    const expMonth = parseInt(MM, 10);
    const expYear = parseInt(YY, 10);
    if (expMonth > 12 || expMonth <= 0) return messageDefault;

    const date = new Date();
    const monthNow = date.getMonth() + 1;
    const yearNow = date.getFullYear() % 100;
    
    if (expYear > yearNow) return true;
    if (expYear === yearNow && expMonth >= monthNow) return true;

    return messageDefault;
  },

  creditCard: function (cardNumber: any) {
    if (!cardNumber) return "Credit card number is invalid";
    if (/[^0-9-\s]/g.test(cardNumber)) return "Credit card number is invalid";

    let cardString = String(cardNumber);

    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0, nDigit = 0, bEven = false;
    cardString = cardString.replace(/\D/g, "");

    for (let n = cardString.length - 1; n >= 0; n--) {
      const cDigit = cardString.charAt(n);
      nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (IsValidCreditCardNumber(cardString) && (nCheck % 10) === 0) || "Credit card number is invalid";
  }
};


function AmexCardnumber(inputtxt: any) {
  const cardno = /^(?:3[47][0-9]{13})$/;
  return cardno.test(inputtxt);
}

function VisaCardnumber(inputtxt: any) {
  const cardno = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  return cardno.test(inputtxt);
}

function MasterCardnumber(inputtxt: any) {
  const cardno = /^(?:5[1-5][0-9]{14})$/;
  return cardno.test(inputtxt);
}

function DiscoverCardnumber(inputtxt: any) {
  const cardno = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  return cardno.test(inputtxt);
}

function DinerClubCardnumber(inputtxt: any) {
  const cardno = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
  return cardno.test(inputtxt);
}

function JCBCardnumber(inputtxt: any) {
  const cardno = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
  return cardno.test(inputtxt);
}

function IsValidCreditCardNumber(cardNumber: any) {

  let cardType = null;
  if (VisaCardnumber(cardNumber)) {
    cardType = "visa";
  } else if (MasterCardnumber(cardNumber)) {
    cardType = "mastercard";
  } else if (AmexCardnumber(cardNumber)) {
    cardType = "americanexpress";
  } else if (DiscoverCardnumber(cardNumber)) {
    cardType = "discover";
  } else if (DinerClubCardnumber(cardNumber)) {
    cardType = "dinerclub";
  } else if (JCBCardnumber(cardNumber)) {
    cardType = "jcb";
  }

  return cardType;
}
