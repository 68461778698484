import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormGroup, Input, Label, Modal } from 'reactstrap';

type ModalPaymentProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  onClickPurchase: (amount: ExtraEnum) => void;
}

enum ExtraEnum {
  TOTALCREDITREQUIRED = 'TOTALCREDITREQUIRED',
  CUSTOM_10 = "CUSTOM_10",
  CUSTOM_20 = "CUSTOM_20",
  CUSTOM_30 = "CUSTOM_30",
}

const ModalPayment = ({ isOpen, toggle, onClickPurchase }: ModalPaymentProps) => {

  const [isCheckOther, setCheckOther] = useState(false)
  const [amount, setAmount] = useState<number>(0)

  return (
    <Modal id="modal-payment"
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      isOpen={isOpen} fade={false} >
      <div style={{ position: 'relative', height: "100%" }} className="d-flex flex-column">
        <div style={{ fontSize: 22, lineHeight: '32px', marginBottom: 30, textAlign: 'center' }}>Payment</div>

        <span style={{ cursor: 'pointer', color: '#828282', position: 'absolute', top: 0, right: 0 }}
          onClick={() => toggle(!isOpen)}
        >
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>


        <FormGroup check>
          <Label check>
            <Input checked={!isCheckOther} type="radio" name="radio1"
              onChange={() => {
                setCheckOther(false)
                setAmount(0)
              }}
            />{' '}The different required
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input checked={isCheckOther} type="radio" name="radio1"
              onChange={() => {
                setCheckOther(true)
                setAmount(10)
              }}
            />{' '}Custom amount
          </Label>
        </FormGroup>

        <FormGroup style={{ padding: '0 20px' }}>
          <FormGroup check>
            <Label check>
              <Input checked={amount === 10} type="radio" name="radio2"
                onChange={() => {
                  setAmount(10)
                  setCheckOther(true)
                }}
              />{' '}$10
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input checked={amount === 20} type="radio" name="radio2"
                onChange={() => {
                  setAmount(20)
                  setCheckOther(true)
                }}
              />{' '}$20
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input checked={amount === 30} type="radio" name="radio2"
                onChange={() => {
                  setAmount(30)
                  setCheckOther(true)
                }}
              />{' '}$30
            </Label>
          </FormGroup>
        </FormGroup>



        <div className="d-flex justify-content-center"
          style={{
            position: 'absolute',
            bottom: 0, left: 0, right: 0,
            textAlign: 'center'
          }}>

          <div className="d-flex align-items-center justify-content-center"
            style={{
              width: 155, height: 40,
              background: '#C8247F', cursor: 'pointer', borderRadius: 10
            }}
            onClick={() => {
              onClickPurchase(amount === 0 ? ExtraEnum.TOTALCREDITREQUIRED
                : amount === 10 ? ExtraEnum.CUSTOM_10 : amount === 20 ? ExtraEnum.CUSTOM_20 : ExtraEnum.CUSTOM_30)
            }}
          >Purchase</div>

        </div>
      </div>
    </Modal >
  );
};

export default ModalPayment;
